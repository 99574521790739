import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ManageInstanceProvider, Company } from '@lighty';
import { ExternalAppsService, StorageService, TranslateService } from '@services';
import { SubscriptionCollection } from '@classes';
import { TimeUtils } from '@common/utils/time.utils';
import * as moment from 'moment';
import { CommonToastService } from '@common2/services/toast.service';

@Component({
    selector: 'msc-manage-instance-watch',
    templateUrl: './watch.component.html',
    styleUrls: ['./watch.component.scss']
})
export class ManageInstanceWatchComponent implements OnInit, OnDestroy {
    private company: Company;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public instance: any;
    public displays: any;
    public dynamicMenu: any;
    public moreMenu: any;
    public currentRoute: string;
    public isActive: boolean;
    public lastChange;
    public statusOptions = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private instanceProvider: ManageInstanceProvider,
        private storageService: StorageService,
        private toastService: CommonToastService,
        private externalAppsService: ExternalAppsService,
        private translateService: TranslateService,
    ) {
    }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.instance = this.storageService.set('content', this.route.snapshot.data.instance);
        this.statusOptions = this.getStatusOptions(this.instance.status);

        this.subscriptionCollection.subscribe = this.route.params.subscribe((params) => {
            this.storageService.set(params.instanceType, this.instance);
            this.storageService.set('instanceType', params.instanceType);
        });

        this.subscriptionCollection.subscribe = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setCurrentRoute();
            }
        });

        this.displays = {
            more: false,
            admin: false,
            dynamic: true
        };

        this.setDynamicMenu();
        this.setMoreMenu();
        this.setCurrentRoute();
        if (this.instance.lastLogCreatedAt) {
            const lastEdit = moment(this.instance.lastLogCreatedAt);
            this.lastChange = TimeUtils.isOlderThanYesterday(lastEdit) ? lastEdit.format('DD.MM.YYYY') : lastEdit.calendar(TimeUtils.TODAY);
        }
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
        this.storageService.delete('content');
        this.storageService.delete(this.storageService.getFlash('instanceType'));
    }

    private setCurrentRoute(): void {
        this.currentRoute = this.router.url.split('/')[6];
    }

    private setDynamicMenu(): void {
        this.dynamicMenu = {
            preparation: [
                {
                    translate: 'words.attendees',
                    icon: 'icon-people',
                    route: ['learners'],
                    display: true
                },
                {
                    translate: 'words.conversations',
                    icon: 'icon-message-circle',
                    route: ['conversations'],
                    display: true
                    // display: this.instance.discussionEnabled
                },
                // {
                //     translate: 'words.content',
                //     icon: 'icon-library',
                //     route: ['contents'],
                //     display: true
                // },
                {
                    translate: 'words.agenda',
                    icon: 'icon-calendar',
                    route: ['agenda'],
                    display: this.storageService.get('instanceType') !== 'external'
                },
                {
                    translate: 'manage.follow-up',
                    icon: 'icon-file-text',
                    route: ['follow-up'],
                    display: this.storageService.get('instanceType') === 'external'
                }
            ],
            ongoing: [
                {
                    translate: 'words.attendees',
                    icon: 'icon-people',
                    route: ['learners'],
                    display: true
                },
                // {
                //     translate: 'words.stats',
                //     icon: 'icon-pie-stats',
                //     route: ['stats', this.instance.type === 'course_instance' ? 'course' : 'traject', this.instance.id, this.instance.type === 'course_instance' ? 'activities' : 'contents'],
                //     display: true
                // },
                {
                    translate: 'words.conversations',
                    icon: 'icon-message-circle',
                    route: ['conversations'],
                    display: true
                    // display: this.instance.discussionEnabled
                },
                {
                    translate: 'words.corrections',
                    icon: 'icon-checkmark-circle-2',
                    route: this.instance.type === 'course_instance' ? ['corrections', 'course', this.instance.id] : ['corrections'],
                    display: this.storageService.get('instanceType') !== 'external'
                },
                {
                    translate: 'words.agenda',
                    icon: 'icon-calendar',
                    route: ['agenda'],
                    display: this.storageService.get('instanceType') !== 'external'
                },
                {
                    translate: 'manage.follow-up',
                    icon: 'icon-file-text',
                    route: ['follow-up'],
                    display: this.storageService.get('instanceType') === 'external'
                }
            ],
            done: [
                // {
                //     translate: 'manage.instance.extend-experience',
                //     icon: 'icon-rocket',
                //     route: ['extend'],
                //     display: true
                // },
                // {
                //     translate: 'words.stats',
                //     icon: 'icon-pie-stats',
                //     route: ['stats', this.instance.type === 'course_instance' ? 'course' : 'traject', this.instance.id, this.instance.type === 'course_instance' ? 'activities' : 'contents'],
                //     display: true
                // },
                // {
                //     translate: 'words.survey',
                //     icon: 'icon-award',
                //     route: ['assessments'],
                //     display: true
                // },
                {
                    translate: 'words.agenda',
                    icon: 'icon-calendar',
                    route: ['agenda'],
                    display: this.storageService.get('instanceType') !== 'external'
                },
                {
                    translate: 'words.conversations',
                    icon: 'icon-message-circle',
                    route: ['conversations'],
                    display: true
                },
                {
                    translate: 'manage.follow-up',
                    icon: 'icon-file-text',
                    route: ['follow-up'],
                    display: this.storageService.get('instanceType') === 'external'
                }
            ]
        };
    }

    private setMoreMenu(): void {
        this.moreMenu = {
            preparation: [
                {
                    translate: 'words.corrections',
                    icon: 'icon-checkmark-circle-2',
                    route: this.instance.type === 'course_instance' ? ['corrections', 'course', this.instance.id] : ['corrections'],
                    display: this.storageService.get('instanceType') !== 'external'
                },
                // {
                //     translate: 'words.survey',
                //     icon: 'icon-award',
                //     route: ['assessments']
                // },
                // {
                //     translate: 'manage.instance.extend-experience',
                //     icon: 'icon-rocket',
                //     route: ['extend']
                // },
                // {
                //     translate: 'words.stats',
                //     icon: 'icon-pie-stats',
                //     route: ['stats', this.instance.type === 'course_instance' ? 'course' : 'traject', this.instance.id, this.instance.type === 'course_instance' ? 'activities' : 'contents']
                // }
            ],
            ongoing: [
                // {
                //     translate: 'words.content',
                //     icon: 'icon-library',
                //     route: ['contents']
                // },
                // {
                //     translate: 'words.survey',
                //     icon: 'icon-award',
                //     route: ['assessments']
                // },
                // {
                //     translate: 'manage.instance.extend-experience',
                //     icon: 'icon-rocket',
                //     route: ['extend']
                // }
            ],
            done: [
                // {
                //     translate: 'words.content',
                //     icon: 'icon-catalogue',
                //     route: ['contents']
                // },
                {
                    translate: 'words.attendees',
                    icon: 'icon-people',
                    route: ['learners'],
                    display: true
                },
                {
                    translate: 'words.corrections',
                    icon: 'icon-checkmark-circle-2',
                    route: this.instance.type === 'course_instance' ? ['corrections', 'course', this.instance.id] : ['corrections'],
                    display: this.storageService.get('instanceType') !== 'external',
                },
                // {
                //     translate: 'words.agenda',
                //     icon: 'icon-calendar',
                //     route: ['agenda']
                // }
            ]
        };
    }

    isActivatedRoute(routes: any): boolean {
        if (routes) {
            const collection = routes.map((route) => {
                return route.route[0];
            });

            if (collection.indexOf(this.currentRoute) > -1) {
                return true;
            }
        }
        return false;
    }

    getStatusOptions(status: string) {
        return [
            {
                label: this.translateService.instant('manage.instance.status-preparation'),
                value: 'preparation',
                isSelected: status === 'preparation',
            },
            {
                label: this.translateService.instant('manage.instance.status-ongoing'),
                value: 'ongoing',
                isSelected: status === 'ongoing',
            },
            {
                label: this.translateService.instant('manage.instance.status-done'),
                value: 'done',
                isSelected: status === 'done',
            },
        ];
    }

    displayed(type: string): void {
        this.displays[type] = !this.displays[type];
    }

    updateInstance(): void {
        const params = {
            context: this.instance.type,
            hidden: this.instance.hidden,
            favorite: this.instance.favorite,
            status: this.instance.status
        };

        this.instanceProvider.update(this.company.id, this.instance.id, params).subscribe((data) => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    archiveInstance(): void {
        this.instance.hidden = !this.instance.hidden;
        const params = {
            context: this.instance.type,
            hidden: this.instance.hidden
        };

        this.instanceProvider.archive(this.company.id, this.instance.id, params).subscribe((data) => {
            this.toastService.onSuccess(this.translateService.instant('toast.saved'));
        });
    }

    changeInstanceStatus(status: string): void {
        this.instance.status = status;
        this.statusOptions = this.getStatusOptions(status);
        this.updateInstance();
    }

    changeInstanceFavorite(): void {
        this.instance.favorite = !this.instance.favorite;
        this.updateInstance();
    }

    back(): void {
        this.router.navigate(['manage', this.company.slug, 'contents']);
    }

    getIcon(): string {
        switch (this.instance.type) {
            case 'traject':
                return 'icon icon-trending-up';
            case 'external_content':
                return 'icon icon-pin-2';
            default:
                return 'icon icon-cube';
        }
    }

    getLogo(): string {
        switch (this.instance.provider.key) {
            case 'mymooc':
                return 'https://static.myskillcamp.com/images/integrations/edflex-blue-no-baseline.png';
            case 'bookboon':
            case 'audio':
                return 'https://static.myskillcamp.com/images/integrations/bookboon.svg';
            case 'external_video':
                return 'https://static.myskillcamp.com/images/integrations/youtube.svg';
            case 'openclassroom':
                return 'https://static.myskillcamp.com/images/integrations/openclassroom.svg';
            case 'udemy':
                return 'https://static.myskillcamp.com/images/integrations/udemy.svg';
            case 'cefora':
                return 'https://static.myskillcamp.com/images/integrations/cefora.svg';
            case 'goodhabitz':
                return 'https://static.myskillcamp.com/images/integrations/goodhabitz.svg';
            case 'trainingexpress':
                return 'https://static.myskillcamp.com/images/integrations/trainingexpress.svg';
            case 'linkedin':
                return 'https://static.myskillcamp.com/images/integrations/linkedin.svg';
            case 'learninghubz':
                return 'https://static.myskillcamp.com/images/integrations/learninghubz.svg';
            case 'coursera-for-campus':
                return 'https://static.myskillcamp.com/images/integrations/coursera-for-campus.svg';
            case 'boclips':
                return 'https://static.myskillcamp.com/images/integrations/boclips.svg';
            case 'netzun':
                return 'https://static.myskillcamp.com/images/integrations/netzun.svg';
            case 'onementor':
                return 'https://static.myskillcamp.com/images/integrations/OneMentor.svg';
            case 'coursera-career-academy':
                return 'https://static.myskillcamp.com/images/integrations/coursera-career-academy.svg';
            case 'xertify':
                return 'https://static.myskillcamp.com/images/integrations/xertify.svg';
            case 'power_bi_dashboards':
                return 'https://static.myskillcamp.com/images/integrations/coursera-for-campus.svg';
            case 'platzi':
                return 'https://static.myskillcamp.com/images/integrations/platzi.svg';
        }
    }
}

<div class="datepicker">
    <div class="calendar">
        <div class="calendar-table">
            <div class="table-condensed">
                <div class="table-head">
                    <div class="month-row">
                        <div class="prev available" (click)="changeMonth('prev')">
                            <i class="icon icon-arrow-ios-left"></i>
                        </div>

                        <div class="month">
                            {{ 'month.' + (currentDate | date: 'MMMM') | translate }} {{ currentDate | date: 'YYYY' }}
                        </div>

                        <div class="next available" (click)="changeMonth('next')">
                            <i class="icon icon-arrow-ios-right"></i>
                        </div>

                    </div>

                    <div class="day-row">
                        <div *ngFor="let day of dayNames[lang]">{{ day }}</div>
                    </div>
                </div>

                <div class="table-body">
                    <div *ngFor="let week of weeks">
                        <div *ngFor="let day of week" [ngClass]="{'today': day.today, 'active': isSelected(day), 'off' : !day.isSameMonth || isLimit(day)}" (click)="selectDay(day)">
                            <span>{{ day.moment.date() }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="actions" *ngIf="actions">
        <div class="flex justify-end gap-2 w-full">
            <msc-button class="msc-button--tiny" (click)="close()">{{ 'words.cancel' | translate }}</msc-button>
            <msc-button class="msc-button--primary msc-button--tiny" palette="secondary" (click)="save()">{{ 'words.save' | translate }}</msc-button>
        </div>
    </div>
</div>

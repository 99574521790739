<div class="container-integration__body col-xs-12 col-md-9 col-9 no-gutter">
    <div class="content-integration content-integration--settings box">
        <ng-container
            *ngIf="isLoading; then loadingTemplate; else defaultTemplate"
        ></ng-container>

        <ng-template #loadingTemplate>
            <div class="content-integration__loading">
                <h4 class="content-integration__loading__heading">
                    {{
                        "pages.organize.integration.udemy.loading.heading"
                            | translate : { name: provider.name }
                    }}
                </h4>
                <div class="spinner spinner--secondary">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <p class="content-integration__loading__description">
                    {{
                        "pages.organize.integration.udemy.loading.description"
                            | translate : { name: provider.name }
                    }}
                </p>
            </div>
        </ng-template>

        <ng-template #defaultTemplate>
            <div class="content-integration__header">
                <h3 class="content-integration__title">
                    {{ "words.settings" | translate }}
                </h3>
            </div>

            <div
                class="content-integration__faq-link"
                *ngIf="provider.key === 'goodhabitz'"
            >
                {{ "providers.goodhabitz.more-info" | translate }}
                <a class="guide" [href]="supportUrl" target="_blank"
                    >{{ "words.here" | translate | lowercase }}.</a
                >
            </div>

            <div class="content-integration__form">
                <div
                    class="content-integration__fieldset"
                    [hidden]="
                        !fields && provider.companyProvider?.catalogueFetching
                    "
                >
                    <div class="content-integration__fieldset__label">
                        {{ "words.state" | translate }}
                    </div>

                    <div class="flex align-center">
                        <div
                            class="switch"
                            [ngClass]="{
                                disabled:
                                    provider.companyProvider
                                        ?.deploymentRunning ||
                                    provider.companyProvider
                                        ?.catalogueFetching ||
                                    (provider.settingsRequired &&
                                        !provider.companyProvider?.status)
                            }"
                            (click)="
                                provider.companyProvider?.deploymentRunning ||
                                provider.companyProvider?.catalogueFetching ||
                                (provider.settingsRequired &&
                                    !provider.companyProvider?.status)
                                    ? null
                                    : modalActivated.onOpen();
                                $event.preventDefault()
                            "
                        >
                            <msc-common2-switch
                                [value]="activated"
                            ></msc-common2-switch>
                        </div>

                        <span
                            class="input__label"
                            *ngIf="provider.companyProvider?.status"
                        >
                            {{ "integrations.link" | translate }}
                        </span>

                        <span
                            class="input__label"
                            *ngIf="!provider.companyProvider?.status"
                        >
                            {{ "integrations.unlink" | translate }}
                        </span>
                    </div>
                </div>
                <div
                    class="content-integration__fieldset"
                    *ngFor="let field of fields"
                >
                    <span *ngIf="field.htmlType == 'label'">
                        <span class="mt-2 mb-2 block">
                            {{ field.translate }}
                        </span>

                        <span
                            class="text-sky-600"
                            *ngIf="field?.dataKey; else dataKeyEmpty"
                        >
                            {{ field.dataKey }}
                        </span>
                        <ng-template #dataKeyEmpty>
                            {{ "type.null" | translate }}
                        </ng-template>
                    </span>
                    <span *ngIf="field.htmlType == 'input'">
                        <msc-input
                            [label]="field.translate"
                            [disabled]="activated"
                            [(ngModel)]="data[field.name]"
                        ></msc-input>
                    </span>
                </div>

                <div
                    class="content-integration__fieldset"
                    *ngIf="provider.key === 'udemy'"
                >
                    <div class="content-integration__fieldset__label">
                        {{ "words.certificate-download" | translate }} myskillcamp metadata.xml
                    </div>

                    <div class="content-integration__fieldset__description">
                        {{ "providers.metadata.description" | translate }}
                        <a
                            href="https://support.myskillcamp.com/hc/en-gb/sections/4859904075037-Udemy-Business"
                            target="_blank"
                            >{{ "providers.metadata.see-more" | translate }}</a
                        >
                    </div>

                    <div class="content-integration__fieldset__export">
                        <msc-button
                            class="msc-button--outline"
                            icon="icon-download-1"
                            (click)="downloadMeta()"
                        >
                        {{ "words.certificate-download" | translate }} metadata.xml
                        </msc-button>
                    </div>
                </div>

                <div
                    class="content-integration__actions"
                    *ngIf="
                        provider.settingsRequired &&
                        activated &&
                        !(
                            provider.companyProvider?.deploymentRunning ||
                            provider.companyProvider?.catalogueFetching
                        )
                    "
                >
                    <msc-button
                        class="msc-button--primary msc-button--large"
                        palette="secondary"
                        (click)="modalEdit.onOpen()"
                    >
                        {{ "words.edit" | translate }}
                    </msc-button>
                </div>

                <div
                    class="content-integration__actions"
                    *ngIf="provider.settingsRequired && !activated"
                >
                    <msc-button
                        class="msc-button--primary msc-button--large"
                        palette="secondary"
                        (click)="save()"
                    >
                        {{ "words.validate" | translate }}
                    </msc-button>
                </div>
            </div>
        </ng-template>
    </div>
</div>

<msc-common-modal
    size="30vw"
    #modalActivated
    [title]="
        (!activated
            ? 'providers.settings.modal-title'
            : 'providers.settings.modal-title-deactivate'
        ) | translate
    "
>
    <ng-template>
        <p *ngIf="!activated">
            {{ "providers.settings.modal-description.activate" | translate }}
        </p>
        <p *ngIf="activated">
            {{ "providers.settings.modal-description.deactivate" | translate }}
        </p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button
                class=""
                palette="primary"
                (click)="modalActivated.onClose()"
                >{{ "common.cancel" | translate }}</msc-button
            >
            <msc-button
                class="msc-button--primary"
                palette="primary"
                (click)="switchActivation(); modalActivated.onClose()"
                >{{
                    (!activated ? "words.connect" : "integrations.unlink")
                        | translate
                }}</msc-button
            >
        </div>
    </ng-template>
</msc-common-modal>

<msc-common-modal
    size="30vw"
    #modalEdit
    [title]="'providers.settings.modal-title.edit' | translate"
>
    <ng-template>
        <p>
            {{ "integrations.modal.description.update.catalogue" | translate }}
        </p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button
                class=""
                palette="primary"
                (click)="modalEdit.onClose()"
                >{{ "common.cancel" | translate }}</msc-button
            >
            <msc-button
                class="msc-button--primary"
                palette="primary"
                (click)="switchActivation(); modalEdit.onClose()"
                >{{ "common.edit" | translate }}</msc-button
            >
        </div>
    </ng-template>
</msc-common-modal>

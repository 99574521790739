<div class="content ">
    <div class="logo-container">
        <div class="logo" [routerLink]="['/', currentJob, company.slug]"
            [style.backgroundImage]="'url(' + companyLogo + ')'"></div>
    </div>
    <!--Custom responsive md == sm  -->
    <div class='bbt-menu xl:hidden md:hidden sm:block '>
        <nav class="nav-header-sm">
            <label for='drop' class='toggle'> <span> <i class="icon icon-menu-arrow"></i></span></label>
            <input type='checkbox' id='drop' />
            <ul class='menu tab'>
                <li class="tabs">
                    <ng-container *ngFor="let role of roles">
                        <div class="tab" *ngIf="!role.hidden" [routerLink]="role.routerLink"
                            [routerLinkActive]="'active'"
                            (click)="role.customNavParams ? navigateTo(role.customNavParams) : null">
                            <span>{{ role.label | translate }}</span>
                        </div>
                    </ng-container>
                </li>
            </ul>
        </nav>
    </div>
    <!--Custom XL -->
    <div class="tabs hidden xl:flex xl:flex xl:flex-row md:block xl:block">
        <ng-container *ngFor="let role of roles">
            <div class="tab" *ngIf="!role.hidden" [routerLink]="role.routerLink" [routerLinkActive]="'active'"
                (click)="role.customNavParams ? navigateTo(role.customNavParams) : null">
                <span>{{ role.label | translate }}</span>
            </div>
        </ng-container>
    </div>
    <div class="hidden md:block xl:block" cdkOverlayOrigin #search="cdkOverlayOrigin">
        <msc-common-search *ngIf="currentJob === 'learn' && access.permissions.companyAccessPlatform"
            [placeholder]="'common.search' | translate" (searchEvent)="displayedSearch($event.value)">
        </msc-common-search>
    </div>
    <!-- Fin Custom -->
    <div class="actions">
        <div class="flex space-x-4 " *ngIf="currentJob === 'organize'">
            <!-- person and pin vinculado con sidebar==  -->
            <msc-button icon="icon-person-add" palette="primary" type="fab" [tooltip]="'words.invite-user' | translate"
                direction="bottom" (click)="addUser()" class="hidden md:block lg:block xl:block"></msc-button>
            <msc-button icon="icon-pin-2" palette="primary" type="fab" [tooltip]="'words.pin-content' | translate"
                direction="bottom" (click)="addPinnedContentAside.onOpen()" class="hidden md:block lg:block xl:block"></msc-button>
        </div>
        <div class="profile">
            <!-- mensaje vinculado con sidebar==  -->
            <div [tooltip]="'words.conversation' | translate" direction="bottom" >
                <msc-button class="msc-button--large" type="fab" icon="icon-email-1" [routerLink]="['/conversation']">
                </msc-button>
            </div>

            <div [tooltip]="'words.profile' | translate" direction="bottom">
                <msc-common-avatar (click)="profileOpen = true" cdkOverlayOrigin #profile="cdkOverlayOrigin"
                    [label]="me?.name" [pictureURL]="me?.picture ? (me?.picture + '?size=64') : null">
                </msc-common-avatar>
            </div>
        </div>
    </div>
</div>

<msc-appcues *ngIf="displays.appcues" (onBack)="displayed('appcues'); displayed('help', true)"
    (onClose)="displayed('help')" [currentJob]="currentJob"></msc-appcues>
<msc-shared-aside-extension #addPinnedContentAside></msc-shared-aside-extension>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" [cdkConnectedOverlayOrigin]="profile"
    [cdkConnectedOverlayOpen]="profileOpen" (backdropClick)="profileOpen = false">
    <ul class="profile-list">
        <li class="flex space-x-2">
            <msc-common-avatar [label]="me?.name" [pictureURL]="me?.picture ? (me?.picture + '?size=64') : null">
            </msc-common-avatar>
            <div class="flex flex-col">
                <span>{{me?.name}}</span>
                <small>{{me?.email}}</small>
            </div>
        </li>
        <li class="menu-item relative" (click)="companiesPickerOpen = true" tabindex="0">
            <span>{{ 'header.profile.change-workspace' | translate }}</span>
            <i class="ml-auto icon icon-arrow-ios-right text-secondary-500"></i>
            <div class="company-list">
                <div class="company-item" *ngFor="let availableCompany of availableCompanies"
                    [class.active]="availableCompany.id === company.id"
                    (click)="onSelectCompany(availableCompany); companiesPickerOpen = false">
                    <i *ngIf="!availableCompany.logo" class="icon icon-company-building"></i>
                    <div class="company-item__logo" *ngIf="availableCompany.logo"
                        [style.backgroundImage]="'url(' + availableCompany.logo + '?size=512' + ')'"></div>
                    <div class="company-item__label">
                        <span>{{ availableCompany.title | translate }}</span>
                        <small class="text-black-300" [innerHTML]="availableCompany.subtitle | translate"></small>
                    </div>
                    <div class="company-item__state" *ngIf="availableCompany.id === company.id">
                        <i class="icon icon-checkmark"></i>
                    </div>
                </div>
            </div>
        </li>
        <li class="menu-item" routerLink="/account/settings" (click)="profileOpen = false">
            <span>{{ 'header.profile.account-settings' | translate }}</span>
        </li>
        <li>
            <ul>
                <li class="menu-item" tabindex="1">
                    <i class="icon icon-question-mark-circle"></i>
                    <span>{{'header.profile.help' | translate }}</span>
                    <i class="ml-auto icon icon-arrow-ios-right text-secondary-500"></i>
                    <ul class="help-list">
                        <li (click)="support()" [attr.id]="currentJob !== 'learn' ? 'chat-help-hook' : 'chat'">
                            <i
                                class="icon {{currentJob !== 'learn' ? 'icon-question-mark-circle' : 'icon-message-circle-1'}} text-secondary-500"></i>
                            <div>
                                <span>
                                    {{ (currentJob !== 'learn' ? 'words.support.manage' : 'words.support.learn') |
                                    translate }}
                                </span>

                                <small>
                                    {{ (currentJob !== 'learn' ? 'words.support.description.manage' :
                                    'words.support.description.learn') | translate }}
                                </small>
                            </div>
                        </li>
                        <li (click)="documentation()">
                            <i class="icon icon-layers-1 text-secondary-500"></i>
                            <div>
                                <span>
                                    {{ 'words.documentation' | translate }}
                                </span>
                                <small>
                                    {{ 'words.documentation.description' | translate }}
                                </small>
                            </div>
                        </li>
                        <li (click)="displayed('appcues')"
                            *ngIf="booted('appcues') && currentJob === 'learn' && access.permissions.companyAccessPlatform">
                            <i class="icon icon-book-open text-secondary-500"></i>
                            <div>
                                <span>
                                    {{ 'words.guides' | translate }}
                                </span>
                                <small>
                                    {{ 'words.guides.description' | translate }}
                                </small>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
        <li class="menu-item" (click)="logout()">
            <span>{{ 'header.profile.logout' | translate }}</span>
        </li>
    </ul>
</ng-template>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'" [cdkConnectedOverlayOrigin]="search"
    [cdkConnectedOverlayOpen]="searchOpen" (backdropClick)="searchOpen = false">
    <ul class="search-container">
        <li class="search-entry border-b" (click)="navigate('catalogue', true); searchOpen = false">
            <div class="tag">
                <div class="tag__text">
                    {{ 'words.catalogue' | translate }}
                </div>
            </div>
            <span>{{ 'header.search.catalogue' | translate:{value: displayedQuery} }}</span>
        </li>
        <li class="search-entry" (click)="navigate('contents', true); searchOpen = false">
            <div class="tag tag--red">
                <div class="tag__text">
                    {{ 'words.my-courses' | translate }}
                </div>
            </div>
            <span>{{ 'header.search.contents' | translate:{value: displayedQuery} }}</span>
        </li>
    </ul>
</ng-template>





import { Component, OnInit, Input } from '@angular/core';
import { Company, ContentProvider } from '@lighty';

@Component({
    selector: 'msc-widget-content',
    templateUrl: './content.component.html'
})
export class WidgetContentComponent implements OnInit {
    @Input() company: Company;
    public contents: any[] = [];
    public loading: boolean = true;
    public cardParams: any;
    public hideContent: false;

    constructor(private contentProvider: ContentProvider) { }

    ngOnInit(): void {
        this.cardParams = {
            isDeletable: true
        };

        this.refresh();
    }

    refresh(): void {
        this.hideContent = false;
        this.loading = true;
        this.contents = [];
        const params = {
            company_id: this.company.id,
            per_page: 4
        };
        this.contentProvider.getContents(params).subscribe((data) => {
            this.contents = data.contents;
            this.loading = false;
        });
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Pagination } from '../../pagination';
import { Camp } from '../../camp';
import { CompanyExperience } from '../experience';
import { environment } from '@env/environment';

@Injectable()
export class CompanyUserProvider {

    constructor(private http: HttpClient) { }

    getUsers(slug: string | number, params?: any): Observable<any> {
        const response = {
            pagination: {},
            users: []
        };

        return this.http.get(`${environment.envVar.API_URL}/company/${slug}/users`, { params }).pipe(
            map((data: any) => {
                response.pagination = new Pagination(data.pagination);
                data.users.map((user) => {
                    response.users.push(user);
                });

                return response;
            })
        );
    }

    getUser(companyId: number, userId: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${companyId}/users/${userId}`).pipe(
            map(data => data)
        );
    }

    updateUser(companyId: number, userId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/company/${companyId}/users/${userId}`, params).pipe(
            map(data => data)
        );
    }

    deleteUser(companyId: number, userId: number): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/company/${companyId}/users/${userId}`).pipe(
            map(data => data)
        );
    }

    getManagers(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/company/${id}/managers`, { params }).pipe(
            map(data => data)
        );
    }

    getCamps(id: number, params?: any): Observable<Camp[]> {
        return this.http.get(`${environment.envVar.API_URL}/company/${id}/available-camps`, { params }).pipe(
            map((data: any) => {
                return data.map((camp) => {
                    return new Camp(camp);
                });
            })
        );
    }

    getExperiences(slug: string): Observable<CompanyExperience[]> {
        return this.http.get(`${environment.envVar.API_URL}/company/${slug}/available-experiences`).pipe(
            map((data: any) => {
                return data.experiences.map((experience) => {
                    return (new CompanyExperience(experience));
                });
            })
        );
    }

    inviteMember(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/users/invite`, params).pipe(
            map(data => data)
        );
    }

    configureMember(id: number, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/users`, params).pipe(
            map(data => data)
        );
    }

    import(id: number, params: any): Observable<any> {
        const formData: FormData = new FormData();
        for (const key in params) {
            if (params.hasOwnProperty(key) && params[key] instanceof Array) {
                for (const p of params[key]) {
                    if (typeof p === 'string') {
                        formData.append(key + '[]', p);
                    } else {
                        formData.append(key + '[]', JSON.stringify(p));
                    }
                }
            } else {
                if (key === 'onboarding') {
                    formData.append(key, JSON.stringify(params[key]));
                } else {
                    formData.append(key, params[key]);
                }
            }
        }

        return this.http.post(`${environment.envVar.API_URL}/admin/companies/${id}/users/import`, formData).pipe(
            map(data => data)
        );
    }

    sendInvitation(slug: string, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/company/${slug}/users/resend-invites`, params).pipe(
            map(data => data)
        );
    }

    update(companyId: number, userId: number, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/admin/companies/${companyId}/users/${userId}`, params).pipe(
            map(data => data)
        );
    }

    getInvitationsCount(id: number, params?: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${id}/users/count-invitations`, { params }).pipe(
            map(data => data)
        );
    }

    getInvitationEmailTemplate(id: number, params?: any) {
        return this.http.get(`${environment.envVar.API_URL}/admin/companies/${id}/invitation/email`, { params }).pipe(
            map(data => data)
        );
    }

    CreateDashboard(experienceId: any, params: any): Observable<any> {
        return this.http.post(`${environment.envVar.API_URL}/common/experience/${experienceId}/dashboard`, params).pipe(
            map(data => data)
        );
    }

    StatusUpdate(experienceId: number, dashboardId: any, params?: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/common/experience/${experienceId}/dashboard/status/${dashboardId}`, params).pipe(
            map(data => data)
        );
    }

    DeleteDashboard(experienceId: number, dashboardId: any,): Observable<any> {
        return this.http.delete(`${environment.envVar.API_URL}/common/experience/${experienceId}/dashboard/${dashboardId}`).pipe(
            map(data => data)
        );
    }

    GetDashboard(experienceId: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/common/experience/${experienceId}/dashboards`).pipe(
            map(data => data)
        );
    }

    UpdateDashboard(experienceId: number, dashboardId: any, params: any): Observable<any> {
        return this.http.put(`${environment.envVar.API_URL}/common/experience/${experienceId}/dashboard/${dashboardId}`, params).pipe(
            map(data => data)
        );
    }

    GetDashboardOnlyActive(experienceId: number): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/common/experience/${experienceId}/dashboards?enabled`).pipe(
            map(data => data)
        );
    }

    ShowDashboard(experienceId: number, dashboardId: any): Observable<any> {
        return this.http.get(`${environment.envVar.API_URL}/common/experience/${experienceId}/dashboard/${dashboardId}`).pipe(
            map(data => data)
        );
    }
}

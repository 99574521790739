<div class="card-container" [class.hidden]="loading || hideContent">
    <!--cursos-->
    <content-card *ngFor="let content of contents; let index=index" [content]="content"
        (onDelete)="$event ? refresh() : hideContent= true"></content-card>

    <div class="flex flex-col space-y-4 items-center bg-white shadow-md py-8 px-4 justify-center rounded"
        *ngIf="!loading && (!contents || contents.length === 0)">
        <msc-empty-state [title]="'overview.recent-contents'" [description]="'empty.content'"
            [cypressHook]="'contents'">
        </msc-empty-state>
    </div>

    <!--noticias responsive-->
    <h3 class="title-noticias-card mt-5 block md:hidden lg:hidden 2xl:hidden xl:hidden">{{ 'words.timeline' | translate
        }}</h3>
    <msc-timeline [context]="'company'" [contextId]="company.id" [isDashboard]="true" [displayCamp]="true"
        [small]="true" class="card-noticias block md:hidden lg:hidden 2xl:hidden xl:hidden"></msc-timeline>
    <!--card eventos-->
    <msc-widget-next-event [company]="company" class="widget-contact-card"></msc-widget-next-event>
    <!--card soporte-->
    <msc-widget-contact [experience]="company?.experience"></msc-widget-contact>
    <!--card recursos-->
    <msc-widget-resource [company]="company" [experience]="company?.experience"></msc-widget-resource>

</div>

<div class="flex center align-center" *ngIf="loading || hideContent">
    <msc-common-spinner></msc-common-spinner>
</div>

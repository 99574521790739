<!--overview aprender= panel -->

<div class="w-full">
    <div class="flex flex-col col-span-2 gap-6">
        <!--row-1-->
        <div class="flex row-banner--col gap-4">
            <div class="grid-banner relative w-100 md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 items-center items-center bg-white rounded-xs shadow-md flex items-center">
                <div class="block-welcome--bg" [ngStyle]="{'background-image': getAnnouncePicture()}"></div>
                <div class="block-welcome__text grow ">
                    <h2 class="capitalize mb-2 text-white">{{ 'words.welcome' | translate }} {{ me.firstname }}</h2>
                    <h4 class="font-bold text-white" *ngIf="experience">{{ experience.displayName ?
                        ('overview.your-space' | translate) + ' ' +
                        experience.displayName : ('overview.your-learning-space' | translate) }}</h4>
                    <p class="font-normal text-white mt-1 mb-4"
                        [innerHTML]="experience ? experience.announceMessage : announce.dashboardAnnounce"></p>
                    <a target="_blank" rel="noreferrer"
                        [href]="experience.announceUrl || announce.dashboardAnnounceLink"
                        *ngIf="(experience && experience.announceUrl) || (announce && announce.dashboardAnnounceLink)">
                        <msc-button palette="primary" icon="icon-external-link-1">
                            {{ 'overview.website-link' | translate }}
                        </msc-button>
                    </a>
                </div>
            </div>
            <div class="grid-statistics w-100 md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 items-center">
                <div class="grow gap-2">
                    <msc-widget-statistics class="grow shadow-sm " [company]="company"></msc-widget-statistics>
                    <msc-widget-resource class="grow hidden" [company]="company"
                        [experience]="experience"></msc-widget-resource>
                </div>
            </div>
        </div>

        <!--row-2 contenido cards-->
        <div class=" grid-principal grid xl:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 grid-rows-2 gap-4">
            <div class="grid-cursos-card col-span-2 col-span-3-2xl row-span-2  rounded py-5">
                <h3 class="mb-3">{{ 'overview.recent-contents' | translate }}</h3>
                <msc-widget-content [company]="company"></msc-widget-content>
            </div>

            <!--noticias-->
            <div
                class="grid-noticias col-span-1 row-span-2 row-span-3-2xl rounded py-5 hidden md:block lg:block 2xl:block xl:block ">
                <h3 class="mb-3 hidden md:block lg:block 2xl:block xl:block">{{ 'words.timeline' | translate }}</h3>
                <div class="timeline overflow-x-hidden overflow-y-scroll">
                    <msc-timeline [context]="'company'" [contextId]="company.id" [isDashboard]="true"
                        [displayCamp]="true" [small]="true"
                        class="card-noticias hidden md:block lg:block 2xl:block xl:block"></msc-timeline>
                </div>
            </div>

            <!--eventos-->
            <div class="grid-eventos row-start-3 col-start-1 col-span-2 rounded py-5">
                <h3 class="mb-2 widget-contact-over">{{ 'overview.next-events' | translate }}</h3>
                <msc-widget-next-event [company]="company" class="widget-contact-over"></msc-widget-next-event>
            </div>
        </div>

    </div>
</div>
<msc-common-modal size="70vw" #modalOnboarding [title]="'onboarding.modal.title' | translate">
    <ng-template>
        <p>{{ 'onboarding.modal.description' | translate }} :</p>
        <ul class="unordered-list">
            <li>{{ 'words.dashboard' | translate }}</li>
            <li>{{ 'words.my-training-courses' | translate }}</li>
            <li *ngIf="sidebar.settings.catalogue">{{ 'words.catalogue' | translate }}</li>
            <li *ngIf="sidebar.settings.group">{{ 'words.communities' | translate }}</li>
        </ul>
        <p>{{ 'onboarding.modal.end-description' | translate }}</p>
        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button palette="primary" (click)="modalOnboarding.onClose()">{{'common.cancel' | translate}}
            </msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="catalogue(); modalOnboarding.onClose()">
                {{'skillcard.description.catalogue' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>

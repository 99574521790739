import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { UrlHelper } from '@helpers';
import { Company } from '@lighty';
import { StorageService, TranslateService, ExternalAppsService, EmitterService } from '@services';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'msc-learn',
    templateUrl: './learn.component.html',
    styleUrls: ['./learn.component.scss'],
})

export class LearnComponent {
    public me: any;
    public company: Company;
    public access: any;
    public sidebar: any;
    public applications: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private storageService: StorageService,
        private translateService: TranslateService,
        private cookieService: CookieService,
        private externalAppsService: ExternalAppsService,
        private emitterService: EmitterService,
    ) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.access = this.storageService.set('access', this.route.snapshot.data.access);
        this.sidebar = this.storageService.set('sidebar', this.route.snapshot.data.sidebar);
        this.applications = this.storageService.set('applications', this.route.snapshot.data.applications);

        this.translateService.setLang(this.me.language.code);
        this.externalAppsService.boot(this.me, this.company, this.access.roles, 'learn', this.applications);

        this.emitterService.set('theme.change', this.company.experience.colorTemplate);

        this.processFavicon();
        this.checkOnboarding();
    }

    private checkOnboarding(): void {
        if (!this.access.permissions.companyAccessPlatform && this.router.url.indexOf('onboarding') === -1) {
            this.router.navigate(['onboarding'], { relativeTo: this.route });
        }
    }

    private processFavicon(): void {
        if (this.company.experience.url?.media) {
            this.emitterService.set('favicon.change', this.company.experience.url.media.pictureUrl);
        }
    }

    loadMoreEmit(): void {
        this.emitterService.loadMoreSection.emit(true);
    }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company, UserProvider, Auth, UtilityProvider, LanguageProvider } from '@lighty';
import { StorageService, AuthService, TranslateService, EmitterService, ExternalAppsService, RedirectService } from '@services';
import { CookieService } from 'ngx-cookie-service';
import { UrlHelper } from '@helpers';
import { find } from '@functions';
import { environment } from '@env/environment';
import { Language } from '@interfaces';
import { take, tap } from 'rxjs/operators';
import { CommonToastService } from '@common2/services/toast.service';
import { ThemingService } from '@common2/services/theming.service';
@Component({
    selector: 'msc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    private me: any;
    private redirection: string;
    private customUrl: string;
    public display: any;
    public companies: Company[];
    public storedCompanies: Company[];
    public loginType: string;
    public query: string;
    public currentLang: Language;
    public companySelected: Company;
    public flagDropdown: boolean = false;
    public loading: boolean = false;
    public action: any;
    public writePassword: boolean = false;
    public displayPassword: boolean = false;
    public displayMessage: boolean = false;
    public message: string;
    public redirect_to: string;
    public role: string;
    public credentials: any = {
        email: '',
        password: ''
    };
    public selectData: any[] = [];
    slideConfig: any;
    public id: string;
    public loader: boolean = false;
    public ssoList: Array<any> = [];
    public baseUrl: string = '';
    public urlParams: any = null;
    public isValidating: boolean = false;
    public currentRoute: string = '';
    public currentUrlSegments: Array<any> = [];
    public domain: string = '';
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private userProvider: UserProvider,
        private toastService: CommonToastService,
        private storageService: StorageService,
        private translateService: TranslateService,
        private emitterService: EmitterService,
        private externalAppsService: ExternalAppsService,
        private redirectService: RedirectService,
        private cookieService: CookieService,
        private utilityProvider: UtilityProvider,
        private languageProvider: LanguageProvider,
        private themingService: ThemingService
    ) {
        this.domain = this.getBaseUrl();
        localStorage.setItem('baseUrl', this.domain);
    }
    ngOnInit(): void {
        this.slideConfig = {
            slidesPerView: 'auto',
            spaceBetween: 16,
            navigation: {
                prevEl: '.prev-btn',
                nextEl: '.next-btn'
            },
            pagination: {
                clickable: true,
            },
            breakpoints: {
                '360': {
                    slidesPerView: 1,
                    spaceBetween: 0,
                },
                '490': {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                '718': {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                '1022': {
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                },
                '1486': {
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                },
                '1700': {
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                }
            },
            allowTouchMove: false,

        }
        const setLang = this.storageService.get('lang');
        if (setLang) {
            this.translateService.setLang(setLang);
        }
        this.utilityProvider.checkMaintenance().subscribe((data) => {
            if (data.state) {
                this.router.navigate(['maintenance']);
            };
        });
        this.route.params.subscribe((params) => {
            if (params.type === 'company') {
                this.loginType = 'company';
                this.me = this.route.snapshot.data.me;
                this.setLanguages(this.me.language.code);
                this.companies = this.route.snapshot.data.companies;
                this.storedCompanies = this.route.snapshot.data.companies;
                this.companySelected = this.companies.length > 0 ? this.companies[0] : null;
                this.display = this.route.snapshot.data.display;
                this.processFavicon();
                this.action = {
                    back: false,
                    next: true,
                    index: 0
                };
                if (!this.storageService.get('me')) {
                    this.storageService.set('me', this.me);
                }
                this.externalAppsService.freshchat('shutdown');

                if (this.externalAppsService.beamer()) {
                    this.externalAppsService.unboot('beamer');
                    this.externalAppsService.beamer().destroy();
                }
                this.checkIfRedirect();
            } else {
                const code = this.translateService.getCurrentLang();
                this.display = this.route.snapshot.data.display;
                this.setLanguages(code);
                this.processFavicon();
                const timeOut = setTimeout(() => {
                    this.loginType = 'normal';
                    clearTimeout(timeOut);
                }, 200);
            }
            this.emitterService.set('theme.change', this.display && this.display.colorTemplate ? this.display.colorTemplate : {});
        });
        this.route.queryParams.subscribe((params) => {
            this.redirection = this.storageService.get('redirect') || null;
            this.credentials.email = params.email || null;
            this.customUrl = params.custom_url;
            this.redirect_to = params.redirect_to;
            this.role = params.role;
            if (params.accessToken && params.refreshToken && params.redirect) {
                this.authService.createCookies({ access_token: params.accessToken, refresh_token: params.refreshToken } as any)
                window.location.href = params.redirect;
            }
            if (this.credentials.email) {
                const timeOut = setTimeout(() => {
                    find('#login').click();
                    clearTimeout(timeOut);
                }, 250);
            }
        });
        ////On custom domain URL
        // check common url
        if (location.origin !== environment.envVar.APP_URL) {
            ////On uuid in url
            if (this.route.snapshot.paramMap.get('id')) {
                //get url id
                this.id = this.route.snapshot.paramMap.get('id');
                //saving ssokey to redirect on logout
                this.route.url.pipe(take(1)).subscribe((url) => {
                    this.currentUrlSegments = url;
                    if (url[0].path == 'sso') {
                        localStorage.setItem('ssoKey', this.id);
                    };
                });
                this.route.queryParams.pipe(take(1)).subscribe(urlParams => {
                    //check for sso providers
                    if (this.id) {
                        this.loader = true;
                        this.authService.getSso(this.id).pipe(take(1)).subscribe(data => {
                            this.baseUrl = data.baseUrl;
                            this.ssoList = data.data;
                            this.loader = false;
                        });
                    };
                    //check for url params
                    if (JSON.stringify(urlParams).length > 2) {
                        //on success
                        if (urlParams.hasOwnProperty('validate') && !this.currentUrlSegments.some(obj => obj.path === 'company')) {
                            let currentTheme: any = JSON.parse(localStorage.getItem('theme'));
                            if (currentTheme){
                                this.themingService.apply({ ...(currentTheme?.colorTemplate?.primaryColor && { primary: currentTheme.colorTemplate.primaryColor }), ...(currentTheme?.colorTemplate?.secondaryColor && { secondary: currentTheme.colorTemplate.secondaryColor }) });
                            }

                            this.isValidating = true;
                            const params = {
                                uuid: urlParams.validate
                            }
                            this.authService.validateSsoAccount(params).pipe(take(1)).subscribe({
                                next: (data) => {
                                    this.authService.createCookies({ access_token: data.accessToken, refresh_token: data.refreshToken } as any);
                                    this.router.navigate(['login', 'company'], { queryParamsHandling: 'merge' });
                                },
                                error: (err) => {
                                    if (err.error.httpCode == 404) {
                                        if (err.error.message == 'brandId') {
                                            this.toastService.onError(this.translateService.instant('toast.login.404.brandId'));
                                        } else {
                                            this.toastService.onError(this.translateService.instant('toast.login.404.number'));
                                        }
                                    }
                                },complete() {
                                    this.isValidating = false;
                                },
                            });
                            //on failed
                        } else if (urlParams.hasOwnProperty('error') && urlParams.error == '406') {
                            this.toastService.onError(this.translateService.instant('toast.login.406'));
                            this.isValidating = false;
                        } else if (urlParams.hasOwnProperty('error') && urlParams.error == '503') {
                            this.toastService.onError(this.translateService.instant('toast.login.503'));
                            this.isValidating = false;
                        }
                    }
                });
            } else {
                let currentUrl = {
                    url: this.domain
                };
                //else check custom url
                this.authService.validateCustomUrlSso(currentUrl).pipe(take(1)).subscribe({
                    next: (data) => {
                        this.loader = true;
                        this.id = data.uuid;
                        if (this.id)
                            this.authService.getSso(data.uuid).pipe(take(1)).subscribe((sso) => {
                                this.baseUrl = sso.baseUrl;
                                this.ssoList = sso.data;
                                this.loader = false;
                            });
                    },
                    error: (err) => {
                        console.log(err);
                    }
                });
            }
        }
    };
    getSelectData(languages: Language[], code) {
        return languages.map((language, index) => {
            const id = index;
            const label = language.name;
            const value = language;
            const isSelected = language.code === code;
            return { id, label, value, isSelected };
        });
    }
    onSelectOption(option) {
        this.selectData = this.selectData.map((selectDatum) => {
            selectDatum.isSelected = selectDatum.id === option.id;
            return selectDatum;
        });
        this.onSelect(option.value);
    }
    setLanguages(code: string = 'en') {
        this.languageProvider
            .get('interface')
            .pipe(tap((response) => this.onTapSetLanguages(response, code)))
            .subscribe();
    }
    onTapSetLanguages(languages: Language[], code: string) {
        if (!Array.isArray(languages)) { return; }
        this.currentLang = languages.find((language) => language.code === code) || languages[0];
        this.storageService.set('lang', this.currentLang.code);
        this.translateService.setLang(this.currentLang.code);
        this.selectData = this.getSelectData(languages, code);
        this.processMessage();
    }
    processMessage(): void {
        this.message = this.display?.loginMessages?.find((loginMessage: any) => {
            return loginMessage.languageId === this.currentLang.id;
        })?.message;
    }
    processFavicon(): void {
        if (this.display?.url?.media) {
            this.emitterService.set('favicon.change', this.display.url.media.pictureUrl);
        }
    }
    getMaxHeight(): string {
        if (this.displayMessage) {
            const view: any = document.querySelector('.froala-view');
            return view.offsetHeight + 'px';
        }
        return '150px';
    }
    hasDifferentHeight(): boolean {
        const view: any = document.querySelector('.froala-view');

        return view.offsetHeight > 200;
    }
    checkIfRedirect(): void {
        if (this.storedCompanies.length === 1) {
            if (this.companySelected.roles.length === 1) {
                this.roleToRedirect(this.companySelected.roles[0]);
            }
        }
    }
    roleToRedirect(role: string) {
        switch (role) {
            case 'company_learner':
                this.redirect('learn');
                break;
            case 'company_author':
                this.redirect('create');
                break;
            case 'company_manager':
                this.redirect('manage');
                break;
            case 'company_administrator':
                this.redirect('organize');
                break;
        }
    }
    trimPassword(): void {
        this.credentials.password = this.credentials.password.trim();
    }
    login(): void {
        this.loading = true;
        if (this.writePassword) {
            this.credentials['base_url'] = this.getBaseUrl();
            this.authService.login(this.credentials).subscribe((auth: Auth) => {
                if (auth.forcedPasswordReset) {
                    this.router.navigate(['password', 'reset'], { queryParams: { email: this.credentials.email, token: auth.forcedPasswordReset } });
                } else {
                    const timeOut = setTimeout(() => {
                        this.loading = false;
                        clearTimeout(timeOut);
                    }, 1500);
                    if (this.redirection) {
                        this.router.navigateByUrl(this.redirection);
                    } else {
                        this.router.navigate(['login', 'company'], { queryParamsHandling: 'merge' });
                    }
                }
            }, (failure) => {
                if (typeof failure.error === 'string' && failure.error.indexOf('Company prefix missing') > -1) {
                    this.toastService.onError(this.translateService.instant('toast.company.prefix.missing'));
                } else if (failure.status === 403) {
                    this.toastService.onError(this.translateService.instant('toast.login.many.attempts'));
                } else if (failure.status === 204) {
                    this.toastService.onError(this.translateService.instant('toast.invalid-credentials'));
                } else if (failure.status === 400) {
                    this.toastService.onError(this.translateService.instant('toast.invalid-credentials'));
                } else if (failure.status === 401) {
                    this.toastService.onError(this.translateService.instant('toast.account-disabled'));
                }
                this.loading = false;
            });
        } else {
            const params = {
                origin: this.customUrl ? this.customUrl : this.redirection ? UrlHelper.getUrl() + this.redirection : UrlHelper.getUrl(),
                email: this.credentials.email
            };
            this.authService.authenticateMethod(params).subscribe((data) => {
                if (data.type === 'sso') {
                    if (data.method === 'saml') {
                        // if (data.protocol === 'saml') {     temporary disable new cloud
                        this.samlForm(data.params, data.url);
                    } else {
                        UrlHelper.navigate({ parts: [data.url], params: { state: data.params.state } }, this.customUrl ? '_blank' : '_self');
                    }
                } else {
                    this.writePassword = true;
                    this.loading = false;
                    const timeOut = setTimeout(() => {
                        find('#password').focus();
                        clearTimeout(timeOut);
                    }, 100);
                }
            }, () => {
                this.loading = false;
            });
        }
    }
    displayed(): void {
        this.flagDropdown = !this.flagDropdown;
    }
    displayedPassword(): void {
        this.displayPassword = !this.displayPassword;
    }
    displayedMessage(): void {
        this.displayMessage = !this.displayMessage;
    }
    search(query?): void {
        this.query = query;
        if (this.query) {
            this.companies = this.storedCompanies.filter((company: Company) => {
                const query = company.name.toLowerCase().match(this.query.toLowerCase());

                if (query) {
                    return query;
                } else if (company.experience && company.experience.displayName) {
                    return company.experience.displayName.toLowerCase().match(this.query.toLowerCase());
                } else {
                    return null;
                }
            });
        } else {
            this.companies = this.storedCompanies;
        }
    }
    selectCompany(company: Company): void {
        this.companySelected = company;
        if (this.role && this.isRole(this.role)) {
            this.roleToRedirect(this.role);
        }
    }
    redirect(type: string): void {
        this.cookieService.set('current_company', this.companySelected.slug, 10, '/', UrlHelper.getDomain(), false, 'Lax');
        this.userProvider.me().subscribe((data) => {
            this.isValidating = false;
            this.storageService.set('me', data);
            this.event(type);
            switch (type) {
                case 'create':
                    this.redirectService.navigate('studio', ['company', this.companySelected.slug, 'home']);
                    break;
                case 'learn':
                case 'manage':
                case 'organize':
                    let url = [type, this.companySelected.slug];
                    if (this.redirect_to) {
                        // url.push(this.redirect_to);
                        url = [...url, ...this.redirect_to.split(',')];
                    }
                    this.router.navigate(url);
                    break;
            }
        });
    }
    event(type: string): void {
        let role = '';
        switch (type) {
            case 'learn':
                role = 'learner';
                break;
            case 'create':
                role = 'author';
                break;
            case 'manage':
                role = 'manager';
                break;
            case 'organize':
                role = 'administrator';
                break;
        }
    }
    isRole(type: string): boolean {
        return this.companySelected.roles.findIndex((role) => {
            return role === type;
        }) !== -1;
    }
    passwordForgotten(): void {
        this.router.navigate(['password', 'forgotten'], { queryParams: { email: this.credentials.email } });
    }
    logout(): void {
        this.emitterService.set('global.logout', { full: true });

    }
    samlForm(params: any, url: string): void {
        const form = document.createElement('form');
        form.target = '_blank';
        form.method = 'POST';
        form.action = url;
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.setAttribute('value', params[key]);
                form.appendChild(input);
            }
        }
        document.body.appendChild(form);
        form.submit();
    }
    onSelect(language: Language): void {
        this.currentLang = language;
        this.translateService.setLang(this.currentLang.code);
        this.processMessage();
    }
   public getBaseUrl(): string {
        const currentUrl = window.location.href;
        const urlParts = currentUrl.split('/');
        const baseUrl = `${urlParts[0]}//${urlParts[2]}`;
        return baseUrl;
      }
}



import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ExternalContentProvider, ExternalContent, Company } from '@lighty';
import { StorageService, EmitterService, ErrorStreamService, TranslateService } from '@services';
import { DownloadHelper, UrlHelper } from '@helpers';
import { finalize } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommonToastService } from '@common2/services/toast.service';

const DEFAULT_PROVIDER_LIST_KEYS: ReadonlyArray<string> = [
    'appcues',
    'bookboon',
    'cefora',
    'cegos',
    'coursera',
    'edflex-blue',
    'email-apside',
    'freshchat',
    'goodhabitz',
    'gotowebinar',
    'learncube',
    'mymooc',
    'openclassroom',
    'trainingexpress',
    'udemy',
    'youtube',
    'linkedin',
    'aws',
    'learninghubz',
    'coursera-for-campus',
    'boclips',
    'netzun',
    'onementor',
    'coursera-career-academy',
    'xertify',
    'power_bi_dashboards',
    'platzi'
];

@Component({
    selector: 'msc-card-external-content',
    templateUrl: './card-external-content.component.html',
    styleUrls: ['./card-external-content.component.scss']
})

export class CardExternalContentComponent {
    @Input() content: ExternalContent | any;
    @Input() type: string;
    @Input() saved: boolean;
    @Input() params?: any;
    @Input() deleteEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() onEvent: EventEmitter<string> = new EventEmitter();
    private company: Company;
    public me: any;
    public loading: any = {
        bookboon: false,
        trainingexpress: false
    };

    constructor(
        private router: Router,
        private externalContentProvider: ExternalContentProvider,
        private toastService: CommonToastService,
        private storageService: StorageService,
        private emitterService: EmitterService,
        private errorStreamService: ErrorStreamService,
        private translateService: TranslateService,
    ) { }

    get logo(): string { return this.getLogo(); }

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.me = this.storageService.get('me');

        this.deleteEmitter.subscribe(() => {
            if (this.type === 'external_video') {
                const params = {
                    provider: 'external_video'
                };

                this.externalContentProvider
                    .unsubscribeVideo(this.content.id, params)
                    .pipe(finalize(() => this.onEvent.emit('completeDelete')))
                    .subscribe(() => {
                        this.toastService.onSuccess(this.translateService.instant('toast.deleted'));
                    });
            } else {
                this.externalContentProvider
                    .unsubscribe(this.content.id)
                    .pipe(finalize(() => this.onEvent.emit('completeDelete')))
                    .subscribe(() => {
                        this.toastService.onSuccess(this.translateService.instant('toast.deleted'));
                    });
            }
        });
    }

    redirect(): void {
        const url = `learn/${this.company.slug}/detail/external-content/${this.type === 'external_content' ? this.content.provider ? this.content.provider.key : 'resource' : this.type}/${this.content.id}`;
        this.router.navigateByUrl(url);
    }

    unsubscribe(): void {
        this.onEvent.emit('delete');
    }

    changeLearnLaterContent(): void {
        if (this.saved) {
            this.deleteSavedContent();
        } else {
            this.addToSavedContents();
        }
    }

    addToSavedContents(): void {
        this.saved = true;
        this.onEvent.emit('addSavedContent');
    }

    deleteSavedContent(): void {
        this.saved = false;
        this.onEvent.emit('deleteSavedContent');
    }

    getNewUrlParams() {
        return {
            company_id: this.company.id,
            provider_id: this.content.provider.id,
            context_id: this.content.id,
            context_type: 'external_content',
        };
    }

    getNewUrl() {
        return this.externalContentProvider
            .generateUrl(this.getNewUrlParams())
            .pipe(
                tap(({ url }) => { this.content.url = url; })
            );
    }

    externalAction(): void {
        switch (this.type) {
            case 'external_content':
                if (this.content.provider) {
                    switch (this.content.provider.key) {
                        case 'bookboon':
                            if (this.content.isDownloadable) {
                                this.subscribeAndDownload();
                            } else {
                                this.redirect();
                            }
                            break;
                        case 'trainingexpress':
                            this.loading.trainingexpress = true;
                            this.getNewUrl().pipe(tap(() => {
                                UrlHelper.navigate(this.content.url)
                                this.loading.trainingexpress = false;
                            })).subscribe();
                            break;

                        case 'netzun':
                            this.loading.trainingexpress = true;
                            this.getNewUrl().pipe(tap(() => {
                                UrlHelper.navigate(this.content.url)
                                this.loading.trainingexpress = false;
                            })).subscribe();
                            break;
                        default:
                            this.subscribe();
                    }
                } else {
                    this.subscribe();
                }
                break;
            case 'external_video':
                this.launchVideo();
                break;
        }
    }

    subscribe(): void {
        if (!this.content.subscribed) {
            this.externalContentProvider
                .subscribeContent(this.content.id)
                .subscribe(() => {
                    this.content.subscribed = true;
                    UrlHelper.navigate(this.content.url);
                    this.emitterService.set('player-light.open', { type: 'external', content: this.content, more: this.content, progressEnabled: this.content.provider ? this.content.provider.progressEnabled : true });
                });
        } else {
            UrlHelper.navigate(this.content.url);
            this.setExternalContentDataAgain(this.content);
            this.emitterService.set('player-light.open', { type: 'external', content: this.content, more: this.content, progressEnabled: this.content.provider ? this.content.provider.progressEnabled : true });
        }
    }

    subscribeAndDownload(): void {
        if (!this.content.archived) {
            this.errorStreamService.locked();
            this.loading.bookboon = true;
            this.toastService.onSuccess(this.translateService.instant('toast.downloading.soon'));

            if (this.content.progress === 100) {
                this.download();
            } else {
                this.externalContentProvider
                    .subscribeContent(this.content.id)
                    .subscribe(() => {
                        this.download();
                    });
            }
        }
    }

    download(): void {
        this.externalContentProvider
            .download(this.content.id)
            .subscribe({
                next: (data) => {
                    this.errorStreamService.unlocked();
                    DownloadHelper.downloadBlob(data, this.content.title);
                    this.loading.bookboon = false;
                    this.toastService.onSuccess(this.translateService.instant('toast.bookboon.download-success'));
                    this.emitterService.set('player-light.open', { type: 'external', content: this.content, more: this.content, progressEnabled: this.content.provider ? this.content.provider.progressEnabled : true });
                },
                error: () => {
                    this.errorStreamService.unlocked();
                    this.loading.bookboon = false;
                    this.toastService.onError(this.translateService.instant('toast.error-occurred'));
                }
            });
    }

    launchVideo(): void {
        if (this.content.subscribed) {
            this.emitterService.set('player-light.open', { type: 'video', content: this.content, more: this.content });
        } else {
            this.externalContentProvider
                .subscribeVideo(this.content.id, this.content)
                .subscribe(() => {
                    this.emitterService.set('player-light.open', { type: 'video', content: this.content, more: this.content });
                });
        }
    }

    getColor(): string {
        switch (this.type) {
            case 'external_content':
                if (this.content.provider) {
                    switch (this.content.provider.key) {
                        case 'bookboon': return 'orange';
                        default: return 'purple';
                    }
                } else {
                    switch (this.content.type) {
                        case 'course': return 'purple';
                        case 'video': return 'blue';
                        case 'article': return 'orange';
                        case 'other': return 'red';
                    }
                }
                break;
            case 'external_video': return 'blue';
        }
    }

    getType(): string {
        switch (this.type) {
            case 'external_content':
                if (this.content.provider) {
                    switch (this.content.provider.key) {
                        case 'openclassroom':
                        case 'cefora':
                        case 'udemy':
                        case 'coursera':
                            return 'words.template';
                        case 'bookboon':
                            return 'words.ebook';
                        default:
                            return this.content.provider.name;
                    }
                } else {
                    switch (this.content.type) {
                        case 'course':
                            return 'words.template';
                        default:
                            return 'words.' + this.content.type;
                    }
                }
            case 'external_video':
                return 'words.video';
        }
    }

    getLogo(): string {
        switch (this.type) {
            case 'external_content':
                // if (!this.content.provider.logo) {
                //     return 'https://static.myskillcamp.com/images/integrations/' + this.content.provider.key + '.svg';
                // } else {
                //     return this.content.provider.logo + '?size=128';
                // }
                const datum = this.content;
                switch (true) {
                    // If there is a customCampMedia , you need to display this media
                    case !!datum && datum.hasOwnProperty('customCampMedia') && !!datum.customCampMedia:
                        return `url(${datum?.customCampMedia?.pictureUrl}?size=256)`;
                    // If there is NO customCampMedia , you need to check whether there is a provider.media
                    case !!datum && datum.hasOwnProperty('provider') && !!datum.provider && datum.provider.hasOwnProperty('media') && !!datum?.provider?.media:
                        // If company.id === provider.responsibleId && provider.responsibleType === 'company')  => display the company_logo and not the provider.media logo
                        return this.company?.id === datum.provider.responsibleId && datum.provider.responsibleType === 'company' ?
                            `url(${this.company?.experience?.displayPicture}?size=256)` : `url(${datum.provider.media.pictureUrl}?size=256)`;
                    // If there is no  provider.media  check if provider is Coursera, Goodhabitz, Bookboon etc...
                    case !!datum && datum.hasOwnProperty('provider') && DEFAULT_PROVIDER_LIST_KEYS.includes(datum?.provider?.key):
                        return `url(https://static.myskillcamp.com/images/integrations/${datum.provider.key}.svg)`;
                    // If there is no  provider.media + NOT IN know providers , you need to display the company media image
                    default:
                        return `url(${this.company?.experience?.displayPicture}?size=256)`;
                }
            case 'external_video':
                return 'https://static.myskillcamp.com/images/integrations/youtube.svg';
        }
    }

    editContent(): void {
        this.emitterService.toggleExtension.emit({ type: 'template', data: this.content });
    }

    stopPropagation(event: any): void {
        event.stopPropagation();
    }

    setExternalContentDataAgain(content: ExternalContent) {
        if (content && content.provider && content.provider.key === 'trainingexpress') {
            this.externalContentProvider
                .getExternalContentDetails(content.id, { context: 'content', company_id: this.company?.id })
                .pipe(
                    tap((content: ExternalContent) => this.onTapGetExternalContentDataAgain(content))
                )
                .subscribe();
        }
    }

    onTapGetExternalContentDataAgain(content: ExternalContent) {
        this.content.url = content.url;
    }
}
